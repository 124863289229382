// utils/recordPageView.js
import { getFunctions, httpsCallable } from "firebase/functions";

const recordPageView = async ({ deliveryId, userId = "anonymous", deviceInfo, source = "" }) => {
  const functions = getFunctions();
  const recordPageViewFunction = httpsCallable(functions, "recordDeliveryPageView");

  try {
    await recordPageViewFunction({
      deliveryId,
      userId,
      deviceInfo: {
        ...deviceInfo,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        source,
        location: window.location.href,
        time: new Date().getTime(),
      },
    });

  } catch (error) {
    console.error("Error recording page view:", error);
  }
};

export default recordPageView;
