import { getAnalytics, logEvent } from "firebase/analytics";
import { isDev } from "./isDev";

export const logFacebookPixelEvent = (eventType, params = {}) => {
  if (isDev()) return; // Ensure this only runs in production

  try {
    if (window.fbq) {
      window.fbq("track", eventType, params);
    } else {
      console.warn("Facebook Pixel not initialized");
    }
  } catch (error) {
    console.error("Error logging Facebook Pixel event:", error);
  }
};

export const logGoogleAdsConversion = (eventType, sendTo, transactionId) => {
  if (isDev) return;
  if (window.gtag) {
    const callback = () => {
      const url = window.location.href; // Get the current URL from the browser
      if (url) {
        window.location = url; // Redirect to the current URL
      }
    };

    window.gtag("event", eventType, {
      send_to: sendTo,
      transaction_id: transactionId,
      event_callback: callback,
    });
  } else {
    console.warn("Google Ads gtag not initialized");
  }
};

export const logFirebaseEvent = (eventName, params = {}) => {
  if (isDev) return;
  try {
    const analytics = getAnalytics(); // Initialize Firebase Analytics
    logEvent(analytics, eventName, params);
  } catch (error) {
    console.error("Error logging Firebase event:", error);
  }
};
