import { isDev } from "./isDev";

const rootUrl = isDev()
      ? "https://us-central1-dev-myxline.cloudfunctions.net"
     : "https://us-central1-kirjautuminen-59fc0.cloudfunctions.net"

  const paths = {
    paytrailPayment: "/paytrailPayment",
    tracking: "/v2_get_tracking_data",
  };
  
  export const getUrl = (requrestedUrl) => {
    return `${rootUrl}${paths[requrestedUrl]}`;
  };
  