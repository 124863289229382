// pages/DeliveryStatusPage.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import colors from "../styles/colors";
import i18n from "../i18n"; // Import the i18n instance
import logo from "../assets/logo-navbar.png"; // Import the logo

const Navbar = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language || "en"; // Get the current language

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage); // Change the language in i18n
    localStorage.setItem("i18nextLng", selectedLanguage); // Save the language setting to localStorage
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: colors.primary,
        height: "100vh",
        padding: "10px 20px",
      }}
    >
      <Box
        component={Link}
        to="/"
        sx={{ display: "flex", justifyContent: "center", my: 2 }}
      >
        <img src={logo} alt="MyXline Logo" style={{ height: "40px" }} />
      </Box>
      <Divider sx={{ backgroundColor: "white", marginBottom: "10px" }} />
      <List>
        <ListItem
          component="a"
          href="https://app.myxline.fi/asiakassivu"
          target="_blank"
          rel="noopener noreferrer"
          button
        >
          <ListItemText
            primary={t("logIn")}
            sx={{ color: "white", textAlign: "center" }}
          />
        </ListItem>
        <ListItem button component={Link} to="/create-user">
          <ListItemText
            primary={t("signUp")}
            sx={{ color: "white", textAlign: "center" }}
          />
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemText
            primary={t("contact")}
            sx={{ color: "white", textAlign: "center" }}
          />
        </ListItem>
      </List>

      {/* Language Selection in Drawer */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <LanguageIcon sx={{ marginRight: "5px", color: "white" }} />
        <Select
          value={currentLanguage}
          onChange={handleLanguageChange}
          variant="standard"
          disableUnderline
          sx={{ color: "white", fontSize: "14px" }}
        >
          <MenuItem value="en">EN</MenuItem>
          <MenuItem value="fi">FI</MenuItem>
          <MenuItem value="sv">SV</MenuItem>
          <MenuItem value="se">SE</MenuItem>
          <MenuItem value="ru">RU</MenuItem>
          <MenuItem value="et">ET</MenuItem>
          <MenuItem value="ar">AR</MenuItem>
          <MenuItem value="fr">FR</MenuItem>
        </Select>
      </Box>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: colors.primary, padding: "10px 20px" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          component="a"
          href="https://myxline.fi"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="MyXline Logo"
            style={{ height: "40px", marginRight: "10px" }}
          />
        </Box>

        {/* Language Selector and Menu Icon for Mobile */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor: colors.primary,
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Language Selector and Links for Desktop */}
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "20px" }}
          >
            <LanguageIcon sx={{ marginRight: "5px", fontSize: "18px" }} />
            <Select
              value={currentLanguage}
              onChange={handleLanguageChange}
              variant="standard"
              disableUnderline
              sx={{ color: "white", fontSize: "14px" }}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="fi">FI</MenuItem>
              <MenuItem value="sv">SV</MenuItem>
              <MenuItem value="se">SE</MenuItem>
              <MenuItem value="ru">RU</MenuItem>
              <MenuItem value="et">ET</MenuItem>
              <MenuItem value="ar">AR</MenuItem>
              <MenuItem value="fr">FR</MenuItem>
            </Select>
          </Box>

          <Button
            component={Link}
            to="/help"
            sx={{ color: "white", textTransform: "none", marginRight: "20px" }}
          >
            {t("help")}
          </Button>
          <Button
            component={Link}
            to="/contact"
            sx={{ color: "white", textTransform: "none", marginRight: "20px" }}
          >
            {t("contact")}
          </Button>
          <Button
            component="a"
            href="https://app.myxline.fi/asiakassivu"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white", textTransform: "none", marginRight: "20px" }}
          >
            {t("logIn")}
          </Button>
          <Button
            component={Link}
            to="/create-user"
            sx={{
              backgroundColor: "white",
              color: "black",
              textTransform: "none",
              borderRadius: "20px",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "#ddd",
              },
            }}
          >
            {t("signUp")}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
