import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import alppila from "../assets/partners/alppila-sahko.jpg";
import helmitalo from "../assets/partners/helmitalo.png";
import keittiomaailma from "../assets/partners/keittiomaailma.jpg";
import ovesta from "../assets/partners/ovesta.png";
import ripatti from "../assets/partners/ripatti.jpg";
import terveysilma from "../assets/partners/terveysilma.jpg";
import kumppani from "../assets/kumppani.png";
import { t } from "i18next"; // Import localization function

const partners = [
  { image: alppila },
  { image: helmitalo },
  { image: keittiomaailma },
  { image: ovesta },
  { image: ripatti },
  { image: terveysilma },
];

const InfoPage = () => {
  return (
    <Box sx={{ padding: "24px" }}>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        {t("infoPage.aboutMyXlineTitle")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("infoPage.aboutMyXlineDescription")}
      </Typography>

      <Typography variant="h5" fontWeight="bold" mt={4} mb={2}>
        {t("infoPage.ourCustomers")}
      </Typography>
      <Grid container spacing={2}>
        {partners.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              component="img"
              src={partner.image}
              alt="Partner"
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: "200px",
                maxHeight: "150px",
                objectFit: "contain",
                borderRadius: "8px",
                margin: "0 auto",
              }}
            />
          </Grid>
        ))}
      </Grid>

      {/* Certification Image */}
      {/* Certification Image and Description */}
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography variant="body2" paragraph>
          {t("infoPage.kumppani")}
        </Typography>
        <Box
          component="img"
          src={kumppani} 
          alt="Kumppani Certification"
          sx={{
            width: "100%",
            height: "auto",
            maxWidth: "300px",
            marginTop: 2,
            borderRadius: "8px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default InfoPage;
