import React, { useEffect, Fragment } from "react";
import { useGeolocated } from "react-geolocated";
import { useDispatch } from "react-redux";
import { setGeolocation, setPickupLocation, setPickupCoordinates } from "../redux/deliverySlice";
import KEYS from '../keys/google.json'

export const setBrowserLocation = (values) => async (dispatch, getState) => {
    if (values) {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${values.latitude},${values.longitude}&key=${KEYS.GEOCODING}`;

        const response = await fetch(url);
        const result = await response.json();
  
        // Check the current state of `from` before updating
        const currentFrom = getState().delivery.delivery.from;
  
        if (!currentFrom && result.status === "OK" && result.results.length > 0) {
          const address = result.results[0].formatted_address;
            const placeId = result.results[0].place_id;
          // If `from` is empty, dispatch the new location address
          dispatch(setPickupLocation({
            address,
            placeId,
          }));
          dispatch(setPickupCoordinates({
            latitude: values.latitude,
            longitude: values.longitude
          })); // Save coordinates to Redux

        } else if (currentFrom) {
          console.log("From location is already set, not updating.");
        } else {
          console.error("Failed to fetch location data");
        }
      } catch (error) {
        console.error("Error fetching browser location: ", error);
      }
    }
  };

const GeoLoc = () => {
  const dispatch = useDispatch();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  // Dispatch geolocation data to Redux when available
  useEffect(() => {
    if (coords) {
      dispatch(
        setGeolocation({
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
      );

      // Fetch the browser location and dispatch it to Redux
      dispatch(setBrowserLocation({
        latitude: coords.latitude,
        longitude: coords.longitude,
      }));
    }
  }, [coords, dispatch]);

  return <Fragment />;
};

export default GeoLoc;
