import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid2,
} from "@mui/material";
import GoogleMap from "./GoogleMap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../App"; // Import Firebase auth
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const DeliveryConfirmationScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [yNumber, setYNumber] = useState("");
  const [invoiceType, setInvoiceType] = useState("e-invoice");

  const db = getFirestore();

  useEffect(() => {
    const fetchDelivery = async (userId) => {
      try {
        const userDoc = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const activeDelivery = userData.my_active_deliveries?.[0];
          setDelivery(activeDelivery || {});
        } else {
          console.log("No user data found.");
        }
      } catch (error) {
        console.error("Error fetching delivery data:", error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchDelivery(user.uid);
      } else {
        setLoading(false);
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [db, navigate]);

  const handleInvoiceSubmission = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
          yNumber,
          companyInfo: {
            yNumber,
            paymentPreference: invoiceType,
            referenceNumber,
          },
        });
      } else {
        alert(t("notAuthenticated"));
      }
    } catch (error) {
      console.error("Error saving payment details:", error);
      alert(t("errorSubmittingDetails"));
    }
  };

  const {
    from,
    to,
    targetTime,
    distanceInfo,
    deliveryTransportType,
    paymentUrl,
    paymentMethod,
    coordinates,
  } = delivery || {};

  return (
    <Box height="100%">
      <Grid2 container>
        {/* Left Side - Details */}
        <Grid2 item size={{ xs: 12, md: 6 }} sx={{ p: "1%", pb: 0 }}>
          <StyledContainer>
            <Typography variant="h4" gutterBottom>
              {t("deliveryCreated")}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {t("waitingForCourier")}
            </Typography>

            {loading ? (
              <Typography variant="body1" color="textSecondary">
                {t("loading")}
              </Typography>
            ) : (
              <Box mt={2} textAlign="left" width="100%">
                {from && (
                  <>
                    <Typography variant="body1" gutterBottom>
                      <strong>{t("pickupPlaceholder")}: </strong>
                      {from || t("notSpecified")}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>{t("dropoffPlaceholder")}: </strong>
                      {to || t("notSpecified")}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>{t("deliveredBy")}: </strong>
                      {targetTime
                        ? new Date(targetTime).toLocaleString()
                        : t("notSpecified")}
                    </Typography>
                    {distanceInfo && (
                      <Typography variant="body1" gutterBottom>
                        <strong>{t("pricingPage.distance")}: </strong>
                        {(distanceInfo.distance / 1000).toFixed(2)} km
                      </Typography>
                    )}
                    <Typography variant="body1" gutterBottom>
                      <strong>
                        {t("deliveryDetailsScreen.paymentMethod")}:{" "}
                      </strong>
                      {paymentMethod
                        ? t(`deliveryDetailsScreen.${paymentMethod}`)
                        : t("notSpecified")}
                    </Typography>
                    {paymentMethod === "card" ? (
                      <Box mt={3}>
                        <Typography variant="body1" gutterBottom>
                          <strong>{t("paymentLink")}: </strong>
                          <a
                            href={paymentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {paymentUrl || t("notSpecified")}
                          </a>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ mt: 1 }}
                        >
                          {t("paymentConfirmationMessage")}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <Box mt={3}>
                          <Typography variant="h6" gutterBottom>
                            {t("paymentDetails")}
                          </Typography>
                          <TextField
                            fullWidth
                            label={t("reference")}
                            placeholder={t("referenceInfo")}
                            variant="outlined"
                            value={referenceNumber}
                            onChange={(e) => setReferenceNumber(e.target.value)}
                            margin="normal"
                          />
                          <TextField
                            fullWidth
                            label={t("yNumber")}
                            variant="outlined"
                            value={yNumber}
                            onChange={(e) => setYNumber(e.target.value)}
                            margin="normal"
                          />
                          <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">
                              {t("invoicePreference")}
                            </FormLabel>
                            <RadioGroup
                              row
                              value={invoiceType}
                              onChange={(e) => setInvoiceType(e.target.value)}
                            >
                              <FormControlLabel
                                value="e-invoice"
                                control={<Radio />}
                                label={t("eInvoice")}
                              />
                              <FormControlLabel
                                value="email-invoice"
                                control={<Radio />}
                                label={t("invoiceToEmail")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleInvoiceSubmission}
                          sx={{ mt: 2 }}
                        >
                          {t("submitPaymentDetails")}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/")}
              sx={{ mt: 2 }}
            >
              {t("newDelivery")}
            </Button>
          </StyledContainer>
        </Grid2>

        {/* Right Side - Map */}
        <Grid2
          item
          size={{ md: 6 }}
          sx={{
            mb: "16px",
            p: "1%",
            borderRadius: "16px",
            maxHeight: "630px", // Set your desired max height here
            overflow: "auto", // Add this to allow scrolling if the content exceeds the max height
          }}
        >
          {useMediaQuery(useTheme().breakpoints.up("md")) && (
            <GoogleMap coordinates={coordinates || {}} />
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DeliveryConfirmationScreen;
