// src/redux/deliverySlice.js
import { createSlice } from "@reduxjs/toolkit";

const SIX_HOURS_IN_SECONDS = 6 * 60 * 60 * 1000; // 4 hours in milliseconds
const initialState = {
  delivery: {
    deliveryId: Math.random().toString(36).substring(2),
    from: "",
    to: "",
    fromPlaceId: "", 
    toPlaceId: "", 
    coordinates: {
      from: { latitude: null, longitude: null }, 
      to: { latitude: null, longitude: null }, 
    },
    distanceInfo: {
      duration: null,
      distance: null,
    }, 

    senderPhone: "",
    senderName: "",
    senderEmail: "",
    receiverPhone: "",
    receiverName: "",

    pickUpTime: new Date().getTime(),
    targetTime: new Date().getTime() + SIX_HOURS_IN_SECONDS,
    deliveryTransportType: "public",
    measurements: {
      weight: 0,
      volume: 0,
      height: 0,
      width: 0
    },

    paymentUrl: null,
    extraInfo: "",
    toExtraInfo: "",
    requirePin: false,
    canBeLeftAtTheDoor: false,
    paymentMethod: "card",
    price: 1,
    geolocation: { latitude: null, longitude: null },

  },
};

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    setPickupLocation: (state, action) => {
      state.delivery.from = action.payload.address;
      state.delivery.fromPlaceId = action.payload.placeId; // Set fromPlaceId

    },
    setDropoffLocation: (state, action) => {
      state.delivery.to = action.payload.address;
      state.delivery.toPlaceId = action.payload.placeId; // Set toPlaceId

    },
    setTargetTime: (state, action) => {
      state.delivery.targetTime = action.payload.targetTime;
    },
    setPickUpTime: (state, action) => {
      state.delivery.pickUpTime = action.payload.pickUpTime;
    },
    setDeliveryTransportType: (state, action) => {
      state.delivery.deliveryTransportType = action.payload;
    },

    setPaymentUrl: (state, action) => {
      state.delivery.paymentUrl = action.payload;
    },
    setPickupNote: (state, action) => {
      state.delivery.extraInfo = action.payload;
    },
    setDropoffNote: (state, action) => {
      state.delivery.toExtraInfo = action.payload;
    },

    setLeaveAtTheDoor: (state, action) => {
      state.delivery.canBeLeftAtTheDoor = action.payload;
    },
    setRecipientDetails: (state, action) => {
      const { receiverName, receiverPhone, requirePin } = action.payload;
      state.delivery.receiverName = receiverName;
      state.delivery.receiverPhone = receiverPhone;
      state.delivery.requirePin = requirePin;
    },
    setRequirePin: (state, action) => {
      state.delivery.requirePin = action.payload;
    },
    setSenderName: (state, action) => {
      state.delivery.senderName = action.payload;
    },
    setSenderPhone: (state, action) => {
      state.delivery.senderPhone = action.payload;
    },
    setSenderEmail: (state, action) => {
      state.delivery.senderEmail = action.payload;
    },
    setReceiverName: (state, action) => {
      state.delivery.receiverName = action.payload;
    },
    setReceiverPhone: (state, action) => {
      state.delivery.receiverPhone = action.payload;
    },
    setGeolocation: (state, action) => {
      // Action to set geolocation data
      state.delivery.geolocation = {
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    },
    setPickupCoordinates: (state, action) => {
      state.delivery.coordinates.from = action.payload; // Save 'from' coordinates
    },
    setDropoffCoordinates: (state, action) => {
      state.delivery.coordinates.to = action.payload; // Save 'to' coordinates
    },
    setDistanceInfo: (state, action) => {
      state.delivery.distanceInfo = action.payload; // Save distance info
    },
    setPreferredPaymentMethod: (state, action) => {
      state.delivery.paymentMethod = action.payload;
    }
    
  },
});

export const {
  setPickupDetails,
  setDropoffDetails,
  setPickupNote,
  setDropoffNote,
  setRecipientDetails,
  setPickupLocation,
  setDropoffLocation,
  setTargetTime,
  setPickUpTime,
  setDeliveryTransportType,
  setGeolocation, 
  setPickupCoordinates,
  setDropoffCoordinates,
  setDistanceInfo,

  setLeaveAtTheDoor,
  setRequirePin,

  setSenderName,
  setSenderPhone,
  setSenderEmail,
  setReceiverName,
  setReceiverPhone,
  setPaymentUrl,
  setPreferredPaymentMethod,
} = deliverySlice.actions;

export default deliverySlice.reducer;
