// colors.js

const colors = {
    primary: '#1976d2',
    secondary: '#dc004e',
    background: '#f0f0f0',
    textPrimary: '#ffffff',
    textSecondary: '#000000',
    success: '#4caf50',
    warning: '#ff9800',
    error: '#f44336',
    info: '#2196f3',
  };
  
  export default colors;
  