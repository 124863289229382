import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

// Define keyframe animation for fading out
const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const FrontPageSlogan = () => {
  const { t } = useTranslation(); // Use the translation hook

  // Array of different slogans for marketing use cases
  const slogans = [
    t("slogan"),
    t("marketingTextLanding1"),
    t("marketingTextLanding2"),
    t("marketingTextLanding3"),
    t("marketingTextLanding4"),
    t("marketingTextLanding5"),
  ];

  const [currentSloganIndex, setCurrentSloganIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false); // Control the fading state
  const [intervalTime, setIntervalTime] = useState(4000); // Start interval time at 4 seconds (4000ms)

  useEffect(() => {
    // Set an interval to change the slogan
    const interval = setInterval(() => {
      setIsFadingOut(true); // Trigger fade-out
      setTimeout(() => {
        setCurrentSloganIndex((prevIndex) => (prevIndex + 1) % slogans.length); // Change the slogan
        setIsFadingOut(false); // Reset fade-out after the slogan change

        // Increase the interval time up to a max of 10000ms (10 seconds)
        setIntervalTime((prevTime) => Math.min(prevTime + 1000, 10000));
      }, 500); // Duration of the fade-out animation
    }, intervalTime);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [slogans.length, intervalTime]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: { xs: "10vh", sm: "20vh" }, // Responsive height: 10vh on mobile, 20vh on desktop
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontWeight: "bold",
          mb: 2,
          animation: isFadingOut ? `${fadeOut} 0.5s ease` : "none", // Only fade out when isFadingOut is true
          opacity: isFadingOut ? 0 : 1, // Control opacity to fade back in instantly after text changes
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" }, // Responsive font sizes
        }}
      >
        {slogans[currentSloganIndex]}
      </Typography>
    </Box>
  );
};

export default FrontPageSlogan;
