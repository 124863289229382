export const generateTimeOptions = () => {
    const times = [];
    let startTime = new Date();
  
    // Round the current time to the nearest full or half hour
    const minutes = startTime.getMinutes();
    if (minutes < 15) {
      startTime.setMinutes(0, 0, 0); // Round down to the nearest hour
    } else if (minutes >= 15 && minutes < 45) {
      startTime.setMinutes(30, 0, 0); // Round to the nearest half-hour
    } else {
      startTime.setHours(startTime.getHours() + 1, 0, 0, 0); // Round up to the nearest full hour
    }
  
    // Generate 15-minute interval time options
    for (let i = 0; i < 96; i++) {
      const timeString = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      times.push(timeString);
      startTime = new Date(startTime.getTime() + 15 * 60000); // Increment by 15 minutes
    }
  
    return times;
  };