import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentPopUp = ({ paymentUrl, setPopUpState, popUpState }) => {
  const { t } = useTranslation(); // Hook for localization

  const handleClose = () => {
    setPopUpState(false);
  };

  return (
    <>
      <Dialog open={popUpState} onClose={handleClose}>
        <DialogTitle>{t("paymentPopup.enablePopUps")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("paymentPopup.popUpBlockedMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("paymentPopup.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentPopUp;
