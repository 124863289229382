import axios from "axios";
import { getUrl } from "./urls";
import { app } from "../App";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  logFirebaseEvent,
  logGoogleAdsConversion,
  logFacebookPixelEvent,
} from "../utils/AnalyticsFunctions";
import { isDev } from "./isDev";

const replaceNullValuesWithEmptyStrings = (delivery) => {
  // Create a new object by spreading the existing delivery object
  return {
    ...delivery,
    coordinates: {
      from: {
        latitude: delivery.coordinates.from.latitude ?? "",
        longitude: delivery.coordinates.from.longitude ?? "",
      },
      to: {
        latitude: delivery.coordinates.to.latitude ?? "",
        longitude: delivery.coordinates.to.longitude ?? "",
      },
    },
    distanceInfo: {
      duration: delivery.distanceInfo.duration ?? "",
      distance: delivery.distanceInfo.distance ?? "",
    },
    measurements: {
      weight: delivery.measurements.weight ?? "",
      volume: delivery.measurements.volume ?? "",
      height: delivery.measurements.height ?? "",
      width: delivery.measurements.width ?? "",
    },
    // Add other properties with the same null check
    deliveryId: delivery.deliveryId ?? "",
    from: delivery.from ?? "",
    to: delivery.to ?? "",
    fromPlaceId: delivery.fromPlaceId ?? "",
    toPlaceId: delivery.toPlaceId ?? "",
    senderPhone: delivery.senderPhone ?? "",
    senderName: delivery.senderName ?? "",
    senderEmail: delivery.senderEmail ?? "",
    receiverPhone: delivery.receiverPhone ?? "",
    receiverName: delivery.receiverName ?? "",
    pickUpTime: delivery.pickUpTime ?? "",
    targetTime: delivery.targetTime ?? "",
    deliveryTransportType: delivery.deliveryTransportType ?? "",
    extraInfo: delivery.extraInfo ?? "",
    toExtraInfo: delivery.toExtraInfo ?? "",
    requirePin: delivery.requirePin ?? false,
    canBeLeftAtTheDoor: delivery.canBeLeftAtTheDoor ?? false,
    price: delivery.price ?? "",
    paymentUrl: delivery.paymentUrl ?? "",
    geolocation: {
      latitude: delivery.geolocation.latitude ?? "",
      longitude: delivery.geolocation.longitude ?? "",
    },
    // CreatedBy and uid can be added directly since they have fixed values
    createdBy: isDev()
      ? "8UzcP1KgoGf8C09UuLjdrMJ9Vci2"
      : "xkDLja7wSzPBcyqxzMZV26FFFPq2",
    uid: isDev()
      ? "8UzcP1KgoGf8C09UuLjdrMJ9Vci2"
      : "xkDLja7wSzPBcyqxzMZV26FFFPq2",
    isDev: isDev(),
  };
};

export const createPaymentUrl = async ({
  deliveryId,
  price,
  email = "test@myxline.fi",
  userId = "1234rgfrcs213e4r",
}) => {
  const url = getUrl("paytrailPayment");

  try {
    const response = await axios.post(url, {
      deliveryId: deliveryId,
      amount: Number(price),
      userId,
      email,
    });

    const href = response.data?.href;
    return href || null; // Return the href if it exists, otherwise return null
  } catch (err) {
    console.error(
      "Payment error:",
      err.response ? err.response.data : err.message
    );
    return null; // Return null if the payment fails
  }
};

export const createPayment = async (delivery) => {
  const functions = getFunctions(app);
  const deliveryWithCorrectValues = replaceNullValuesWithEmptyStrings(delivery);
  const saveDelivery = httpsCallable(functions, "saveDeliveryAndNotifySlack");

  logFirebaseEvent("createPayment");
  logFacebookPixelEvent("Purchase", { value: deliveryWithCorrectValues.price });
  logGoogleAdsConversion(
    "conversion",
    "AW-328068388/oHt_CNGk4t4ZEKTat5wB",
    delivery.deliveryId
  );

  try {
    const result = await saveDelivery(deliveryWithCorrectValues);
  } catch (error) {
    console.error("Error saving delivery or notifying Slack:", error);
  }
};
