import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { t } from 'i18next';

const PricingPage = () => {
  const pricingData = [
    { distance: "0 - 2 km", weight: "≤ 30 kg", price: "7 €", deliveryTime: "0-3 h" },
    { distance: "2 - 5 km", weight: "≤ 30 kg", price: "8 €", deliveryTime: "0-3 h" },
    { distance: "5 - 10 km", weight: "≤ 30 kg", price: "13 €", deliveryTime: "0-3 h" },
    { distance: "10 - 13 km", weight: "≤ 30 kg", price: "16 €", deliveryTime: "0-3 h" },
    { distance: "13 - 18 km", weight: "≤ 30 kg", price: "24 €", deliveryTime: "0-3 h" },
    { distance: "18 - 22 km", weight: "≤ 30 kg", price: "29 €", deliveryTime: "0-4 h" },
    { distance: "22 - 30 km", weight: "≤ 30 kg", price: "38 €", deliveryTime: "0-4 h" },
    { distance: "30 - 40 km", weight: "≤ 30 kg", price: "44 €", deliveryTime: "0-4 h" },
    { distance: "40 - 50 km", weight: "≤ 30 kg", price: "57 €", deliveryTime: "0-4 h" },
    { distance: "50 - 500 km", weight: "≤ 30 kg", price: "13.4 € + 0.9 € *km", deliveryTime: "-" },
  ];

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        {t('pricingPage.pricingTitle')}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('pricingPage.distance')}</TableCell>
              <TableCell>{t('pricingPage.weightLimit')}</TableCell>
              <TableCell>{t('pricingPage.price')}</TableCell>
              <TableCell>{t('pricingPage.deliveryTime')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.distance}</TableCell>
                <TableCell>{row.weight}</TableCell>
                <TableCell>{row.price} {' + '} {t('tax')}</TableCell>
                <TableCell>{row.deliveryTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Extra services */}
      <Typography variant="h6" fontWeight="bold" mt={4} mb={2}>
        {t('pricingPage.additionalServices')}
      </Typography>
      <Typography variant="body1">
        {t('pricingPage.invoiceFee')}<br />
        {t('pricingPage.floorSurcharge')}<br />
        {t('pricingPage.rushDelivery')}<br />
      </Typography>
    </Box>
  );
};

export default PricingPage;
