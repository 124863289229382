import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import KEYS from "../keys/google.json";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button"; // Button for "Peruuta"
import { useTheme } from "@mui/material/styles";
import config from "../keys/config.json";
import { t } from "i18next";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps({
  label,
  value,
  onChange,
  isMobile = false,
}) {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false); // State to control the popup
  const loaded = React.useRef(false);
  const theme = useTheme();

  // Check if the device is mobile based on the screen width

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${KEYS.GEOCODING}&libraries=places&language=${config.language}`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        if (autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(
            { ...request, componentRestrictions: { country: config.country } },
            callback
          );
        }
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google && window.google?.maps?.places) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleOpen = () => {
    if (isMobile) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange(null);
    }
    handleClose(); // Close the popup when an item is selected
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && options.length > 0) {
      handleChange(options[0]);
      setOpen(false); // Close the options list
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <TextField
            label={label}
            fullWidth
            onClick={handleOpen}
            InputProps={{
              startAdornment: (
                <LocationOnIcon sx={{ color: "action.active", mr: 1 }} />
              ),
            }}
            value={inputValue}
          />
          <Dialog open={open} onClose={handleClose} fullScreen>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
              <Typography variant="h6">{label}</Typography>
              <Button onClick={handleClose} color="primary" sx={{ textTransform: 'none' }}>
                {t("Peruuta")}
              </Button>
            </Box>
            <DialogContent>
              <Autocomplete
                autoFocus // Autofocus on the input when the dialog opens
                open={open} // Control the open state
                onOpen={() => setOpen(true)} // Open the list when the component gains focus
                onClose={() => setOpen(false)} // Close the list when clicking outside
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText={t("noLocations")}
                onChange={(event, newValue) => handleChange(newValue)}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={handleKeyDown} // Move the onKeyDown handler here
                    {...params}
                    label={label}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <LocationOnIcon
                          sx={{ color: "action.active", mr: 1 }}
                        />
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;

                  const structuredFormatting =
                    option.structured_formatting || {};
                  const mainText = structuredFormatting.main_text || "";
                  const secondaryText =
                    structuredFormatting.secondary_text || "";
                  const matches =
                    structuredFormatting.main_text_matched_substrings || [];

                  const parts = parse(
                    mainText,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li key={key} {...optionProps}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {parts.map((part, index) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                          >
                            {secondaryText}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Autocomplete
          autoFocus
          open={open} // Control the open state
          onOpen={() => setOpen(true)} // Open the list when the component gains focus
          onClose={() => setOpen(false)} // Close the list when clicking outside
          sx={{
            width: "100%",
            marginBottom: "16px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText={t("noLocations")}
          onChange={(event, newValue) => handleChange(newValue)}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              onKeyDown={handleKeyDown} // Move the onKeyDown handler here
              {...params}
              label={label}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <LocationOnIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;

            const structuredFormatting = option.structured_formatting || {};
            const mainText = structuredFormatting.main_text || "";
            const secondaryText = structuredFormatting.secondary_text || "";
            const matches =
              structuredFormatting.main_text_matched_substrings || [];

            const parts = parse(
              mainText,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            return (
              <li key={key} {...optionProps}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {secondaryText}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    </>
  );
}
