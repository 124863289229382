import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { useTranslation } from "react-i18next";

const DownloadPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f0f0",
        padding: "20px",
      }}
    >
      {/* Company Login Text */}
      <Typography
        variant="h4"
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: { xs: "24px", md: "32px" }, // Responsive font size
        }}
      >
           {t("logInAsCompany")}
      </Typography>
      {/* Company Login Button */}
      <Button
        variant="outlined"
        color="primary"
        href="https://app.myxline.fi/asiakassivu"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textTransform: "none",
          marginBottom: "20px",
          width: { xs: "200px", md: "250px" },
        }}
      >
        {t("logIn")}
      </Button>

      <Typography
        variant="h4"
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: { xs: "24px", md: "32px" }, // Responsive font size
        }}
      >
        {t("downloadPage.downloadAppTitle")}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        href="https://apps.apple.com/us/app/id1502213567"
        startIcon={<AppleIcon />}
        sx={{
          backgroundColor: "#000000",
          color: "#ffffff",
          textTransform: "none",
          marginBottom: "10px",
          width: { xs: "200px", md: "250px" }, // Responsive width
          "&:hover": {
            backgroundColor: "#333333",
          },
        }}
      >
        {t("downloadPage.downloadOnAppStore")}
      </Button>

      <Button
        variant="contained"
        color="primary"
        href="https://play.google.com/store/apps/details?id=com.miwotec.xline"
        startIcon={<AndroidIcon />}
        sx={{
          backgroundColor: "#3DDC84",
          color: "#ffffff",
          textTransform: "none",
          width: { xs: "200px", md: "250px" }, // Responsive width
          "&:hover": {
            backgroundColor: "#34A853",
          },
        }}
      >
        {t("downloadPage.getItOnGooglePlay")}
      </Button>
    </Box>
  );
};

export default DownloadPage;
