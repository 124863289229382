import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import CreateDeliveryContainer from '../components/CreateDeliveryContainer';
import BrowserLocation from '../utils/BrowserLocation';
import { Fab, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HomePageSections from '../components/HomePageSections';
import colors from '../styles/colors';
import backgroundImage from '../assets/tausta.png';
import recordPageView from "../utils/recordPageView";

const Home = () => {
  const location = useLocation(); // Initialize useLocation
  const [showScroll, setShowScroll] = useState(false);

  // Access the source parameter if it exists
  const source = location.state?.source;

  useEffect(() => {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    };

    // Check if pathname matches the /eurolla/${id} format
    const eurollaMatch = location.pathname.match(/^\/eurolla\/(\w+)/);

    if (eurollaMatch) {
      const deliveryId = eurollaMatch[1];
      recordPageView({
        deliveryId,
        userId: "anonymous",
        deviceInfo,
        source,
      });
    }
  }, [source]);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <BrowserLocation />
      <CreateDeliveryContainer />
      <HomePageSections />
      <Fab
        size="small"
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          display: showScroll ? 'flex' : 'none',
          backgroundColor: colors.primary,
          color: colors.textPrimary,
          '&:hover': {
            backgroundColor: colors.primary,
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );
};

export default Home;
