import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  CardMedia, 
  Button,
  Grid,
  useTheme
} from '@mui/material';
import { 
  Smartphone as SmartphoneIcon,
  Computer as ComputerIcon,
  ArrowForward as ArrowForwardIcon 
} from '@mui/icons-material';

const Flowers = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  // Function to detect iOS
  const isIOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  };

  // Function to handle mobile app redirect
  const handleMobileRedirect = () => {
    const iosAppUrl = 'https://apps.apple.com/us/app/id1502213567';
    const androidAppUrl = 'https://play.google.com/store/apps/details?id=com.miwotec.xline';
    window.location.href = isIOS() ? iosAppUrl : androidAppUrl;
  };

  // Function to handle web app redirect
  const handleWebRedirect = () => {
    window.location.href = 'https://app.myxline.fi/asiakassivu';
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography 
        variant="h3" 
        component="h1" 
        align="center" 
        gutterBottom
        color="primary"
      >
        {t('flowers.title')}
      </Typography>
      
      <Typography 
        variant="h5" 
        align="center" 
        color="text.secondary" 
        paragraph
      >
        {t('flowers.subtitle')}
      </Typography>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {/* Mobile Card */}
        <Grid item xs={12} md={6}>
          <Card 
            sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: '0.3s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows[4]
              }
            }}
          >
            {/* <CardMedia
              component="img"
              height="200"
              image="/images/mobile-app.jpg"
              alt={t('flowers.mobileApp.title')}
              sx={{ objectFit: 'cover' }}
            /> */}
            <CardContent sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SmartphoneIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h5" component="h2">
                  {t('flowers.mobileApp.title')}
                </Typography>
              </Box>
              <Typography paragraph>
                {t('flowers.mobileApp.description')}
              </Typography>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={handleMobileRedirect}
                fullWidth
              >
                {t('flowers.mobileApp.button')}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Web Card */}
        <Grid item xs={12} md={6}>
          <Card 
            sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              transition: '0.3s',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows[4]
              }
            }}
          >
            {/* <CardMedia
              component="img"
              height="200"
              image="/images/web-app.jpg"
              alt={t('flowers.webApp.title')}
              sx={{ objectFit: 'cover' }}
            /> */}
            <CardContent sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ComputerIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h5" component="h2">
                  {t('flowers.webApp.title')}
                </Typography>
              </Box>
              <Typography paragraph>
                {t('flowers.webApp.description')}
              </Typography>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={handleWebRedirect}
                fullWidth
              >
                {t('flowers.webApp.button')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Flowers;