import { useRef } from "react";
import { Box, Typography, Button, TextField, Switch } from "@mui/material";
import { Edit } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Styles for the phone input component

const DeliveryDetailsField = ({
  icon,
  fieldType,
  filledText,
  placeHolder,
  editingFieldOpen,
  toggleEditingFields,
  t,
  editingFieldConfig = [], // Array of field configurations
}) => {
  const ref = useRef(null);
  const firstInputRef = useRef(null); // Ref for the first input field

  const handleEditClick = () => {
    toggleEditingFields(fieldType, true);

    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });

    // Focus the first input field after a slight delay (to ensure the input renders)
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus(); // Focus the first input field if it's a TextField
      } else {
        const phoneInput = document.querySelector(".phone-input"); // Query the phone input class
        if (phoneInput) {
          phoneInput.focus(); // Manually focus the phone input
        }
      }
    }, 300); // Adjust the delay as needed
  };

  const renderFields = (type, value, placeHolder, onChange, inputRef) => {
    switch (type) {
      case "TextField":
        return (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputRef={inputRef}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px", // Ensures the same height as the phone input
              },
            }}
          />
        );
      case "TextFieldMultiline":
        return (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputRef={inputRef}
            multiline
            rows={2} // Adjust the number of rows as needed
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                padding: "10px", // Adjust padding for multiline input
              },
            }}
          />
        );
      case "EmailField":
        return (
          <TextField
            fullWidth
            type="email"
            variant="outlined"
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputRef={inputRef}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
              },
            }}
          />
        );
      case "PhoneNumber":
        return (
          <TextField
            fullWidth
            variant="outlined"
            placeholder="1234567"
            value={value}
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numeric characters
              onChange(newValue); // Update the state with the numeric value
            }}
            inputRef={inputRef}
            InputProps={{
              startAdornment: (
                <Typography
                  variant="body1"
                  sx={{ paddingRight: "8px", fontWeight: "bold" }}
                >
                  +358
                </Typography>
              ),
              inputMode: "numeric", // Makes the mobile keyboard show numbers only
              pattern: "[0-9]*", // Ensures the field only accepts numbers
            }}          
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
              },
            }}
          />
        );
      case "NumericField":
        return (
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            placeholder={placeHolder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            inputRef={inputRef}
            InputProps={{ inputProps: { min: 0, step: 0.1 } }}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
              },
            }}
          />
        );
      case "Switch":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ mr: 2 }}>{placeHolder}</Typography>
            <Switch
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              inputRef={inputRef}
              color="primary"
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        ref={ref}
      >
        
        <Box display="flex" alignItems="center" sx={{ maxWidth: "60%", overflow: "hidden" }}>
          {icon}
          <Typography variant="body1">{filledText || placeHolder}</Typography>
        </Box>
        {!editingFieldOpen[fieldType] && (
          <Button
            startIcon={<Edit />}
            variant="outlined"
            onClick={handleEditClick}
          >
            {t("edit")}
          </Button>
        )}
      </Box>

      {editingFieldOpen[fieldType] && (
        <div style={{ marginTop: 10 }}>
          {editingFieldConfig.map((field, index) => (
            <Box key={index}>
              {renderFields(
                field.type,
                field.value,
                field.placeHolder,
                field.onChange,
                index === 0 ? firstInputRef : null
              )}
            </Box>
          ))}

          <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => toggleEditingFields(fieldType, false)}
            >
              {t("save")}
            </Button>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default DeliveryDetailsField;
