import React from 'react';
import { Box, Typography, Grid, Button, Link } from '@mui/material';
import { t } from 'i18next'; // Import localization function

const ContactPage = () => {
  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        {t('contactPage.title')}
      </Typography>
      
      <Grid container spacing={3}>

        {/* Customer Service Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" fontWeight="bold">{t('contactPage.customerService')}</Typography>
          <Typography variant="body1" mt={2}>
            {t('contactPage.phone')}: <Link href="tel:+358102005000">010 200 5000</Link><br />
            {t('contactPage.email')}: <Link href="mailto:info@myxline.fi">info@myxline.fi</Link><br />
            {t('contactPage.openHours')}: {t('contactPage.openHoursDetails')}<br />
          </Typography>
        </Grid>

        {/* Button for further help */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            href="https://myxline.fi/yhteystiedot"
          >
            {t('contactPage.visitFullContactPage')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactPage;
