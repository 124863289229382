import React from "react";
import LocationScreen from "./LocationScreen";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FrontPageSlogan from "./FrontPageSlogan";
import {
  logFirebaseEvent,
  logGoogleAdsConversion,
} from "../utils/AnalyticsFunctions";

const CreateDeliveryContainer = () => {
  const navigate = useNavigate();

  const seePrices = () => {
    logFirebaseEvent("seePrices");

    // This is for the Google Ads conversion tracking *naming is not good...
    logGoogleAdsConversion("siirtyminen_play__tai_app_storeen");

    // Navigate to the next page
    navigate("/d");
  };

  return (
    <Card
      sx={{
        padding: 2,
        mt: 2,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: { xs: "100%", md: 600 },
        margin: "0 auto",
      }}
    >
      <CardContent>
        <FrontPageSlogan />
        <LocationScreen onSubmit={seePrices} />
      </CardContent>
    </Card>
  );
};

export default CreateDeliveryContainer;
