import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  CalendarToday,
  AccessTime,
  DirectionsBus,
  ShoppingBag,
  DirectionsCar,
  LocalShipping,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setPickupLocation,
  setDropoffLocation,
  setPickUpTime,
  setDeliveryTransportType,
  setPickupCoordinates,
  setDropoffCoordinates,
  setDistanceInfo,
  setTargetTime,
} from "../redux/deliverySlice";
import MapsAutoComplete from "./MapsAutoComplete";
import {
  getCoordinatesByPlaceId,
  getDirectionsByPlaceIds,
} from "../utils/LocationFunctions";
import { generateTimeOptions } from "../utils/TimeFunctions";
import colors from "../styles/colors";

const LocationScreen = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeEdited, setTimeEdited] = useState(false);

  // Get current state from Redux
  const delivery = useSelector((state) => state.delivery.delivery);
  const {
    from,
    fromPlaceId,
    to,
    toPlaceId,
    pickUpTime,
    deliveryTransportType,
  } = delivery;

  const [date, setDate] = useState(
    pickUpTime
      ? new Date(pickUpTime).toISOString().slice(0, 10)
      : new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    const fetchDirections = async () => {
      if (fromPlaceId && toPlaceId) {
        const directions = await getDirectionsByPlaceIds(
          fromPlaceId,
          toPlaceId
        );
        if (directions) {
          // Dispatch the distance and duration to Redux
          dispatch(
            setDistanceInfo({
              distance: directions.distance.value, // meters
              duration: directions.duration.value, // seconds
            })
          );
          const estimateTargetTime = new Date(
            new Date().getTime() + directions.duration.value * 1000
          );
          const nowPlusSixHours = new Date(new Date().getTime() + 6 * 60 * 60 * 1000);
          if (estimateTargetTime.getTime() > nowPlusSixHours.getTime()) {
            const targetEstimatePlusTwoHours = new Date(estimateTargetTime.getTime() + 2 * 60 * 60 * 1000);
            dispatch(setTargetTime({ targetTime: targetEstimatePlusTwoHours }));
          }

        }
      }
    };
    fetchDirections();
  }, [fromPlaceId, toPlaceId, dispatch]);

  const handleSizeChange = (event, newSize) => {
    if (newSize !== null) {
      dispatch(setDeliveryTransportType(newSize)); // Update Redux state for transport type
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    dispatch(setPickUpTime({ targetTime: new Date(e.target.value).getTime() })); // Convert to Unix timestamp and update state
  };

  const handleTimeChange = (e) => {
    setTimeEdited(true);
    dispatch(
      setPickUpTime({
        pickUpTime: new Date(`${date}T${e.target.value}`).getTime(),
      })
    ); // Convert to Unix timestamp and update state
  };

  const handlePickupChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setPickupLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setPickupCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setPickupLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setPickupCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  const handleDropoffChange = async (location) => {
    if (location) {
      const { description: address, place_id: placeId } = location;
      dispatch(setDropoffLocation({ address, placeId })); // Save to Redux

      // Fetch coordinates for the selected placeId
      const coordinates = await getCoordinatesByPlaceId(placeId);
      if (coordinates) {
        dispatch(setDropoffCoordinates(coordinates)); // Save coordinates to Redux
      }
    } else {
      dispatch(setDropoffLocation({ address: "", placeId: "" })); // Clear the value
      dispatch(setDropoffCoordinates({ latitude: null, longitude: null })); // Clear coordinates
    }
  };

  const submitText =
    deliveryTransportType === "public" ? t("buttonOther") : t("buttonOther");
  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        {/* Replaced Pickup and Dropoff TextFields with MapsAutoComplete Autocomplete */}
        <Grid item xs={12}>
          <MapsAutoComplete
            label={t("pickupPlaceholder")}
            value={from}
            onChange={(location) => {
              if (location) {
                handlePickupChange(location); // Dispatch the selected location
              } else {
                handlePickupChange({ description: "" }); // Clear the pickup location
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MapsAutoComplete
            label={t("dropoffPlaceholder")}
            value={to}
            onChange={(location) => {
              if (location) {
                handleDropoffChange(location); // Dispatch the selected location
              } else {
                handleDropoffChange({ description: "" }); // Clear the dropoff location
              }
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            type="date"
            variant="outlined"
            value={date}
            onChange={handleDateChange}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <CalendarToday />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            value={
              pickUpTime
                ? new Date(pickUpTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })
                : ""
            }
            onChange={handleTimeChange}
            displayEmpty
            variant="outlined"
            startAdornment={
              <IconButton>
                <AccessTime />
              </IconButton>
            }
            renderValue={(selected) => {
              // Show "Now" when value is empty
              return timeEdited ? selected : t("now");
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5, // Show 4 rows at a time (48px is the height of each row)
                },
              },
            }}
          >
            {generateTimeOptions().map((timeOption) => (
              <MenuItem key={timeOption} value={timeOption}>
                {timeOption}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Grid container justifyContent="center">
            <ToggleButtonGroup
              value={deliveryTransportType}
              exclusive
              onChange={handleSizeChange}
              aria-label={t("deliverySize")}
              sx={{
                mb: 2,
                width: {
                  xs: "auto", // Use auto width on mobile screens
                  md: "60%", // Maintain 60% width on medium and larger screens
                },
                display: "flex",
                justifyContent: "center", // Center the buttons
              }}
            >
              <ToggleButton value="public" aria-label={t("smallSize")}>
                <ShoppingBag sx={{ marginRight: 1 }} /> {t("smallSize")}
              </ToggleButton>
              <ToggleButton value="car" aria-label={t("mediumSize")}>
                <DirectionsCar sx={{ marginRight: 1 }} /> {t("mediumSize")}
              </ToggleButton>
              <ToggleButton value="van" aria-label={t("largeSize")}>
                <LocalShipping sx={{ marginRight: 1 }} /> {t("largeSize")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            sx={{
              width: {
                xs: "100%", // Full-width on mobile screens
                md: "60%", // 60% width on medium and larger screens
              },
              padding: "12px 0",
              backgroundColor: colors.primary, // Use primary color from colors.js
              color: colors.textPrimary, // Set text color
              "&:hover": {
                backgroundColor: colors.primary, // Keep the primary color on hover
              },
            }}
            onClick={() => onSubmit()}
          >
            {submitText}
          </Button>
        </Grid>
        <Grid item xs={12} textAlign="center">
          {deliveryTransportType === "public" && (
            <Typography variant="body2" color="textSecondary">
              {t("smallSizeDetails")} {/* Localized */}
            </Typography>
          )}
          {deliveryTransportType === "car" && (
            <Typography variant="body2" color="textSecondary">
              {t("mediumSizeDetails")} {/* Localized */}
            </Typography>
          )}
          {deliveryTransportType === "van" && (
            <Typography variant="body2" color="textSecondary">
              {t("largeSizeDetails")} {/* Localized */}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationScreen;
