import React, { useState } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  Button, 
  Box, 
  CircularProgress,
  Alert,
  AlertTitle,
  useTheme,
  useMediaQuery 
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import logo from '../assets/logo-large.png';
import { db } from "../App";

const Newsletter = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  
  // Add theme and media query hooks for responsive design
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleUnsubscribe = async () => {
    setLoading(true);
    try {
      const emailRef = doc(db, 'emails', id);
      await setDoc(emailRef, {
        id,
        unsubscribed: true,
        unsubscribedAt: new Date().toISOString(),
        createdAt: new Date().getTime()
      }, { merge: true });
      setSuccess(true);
    } catch (err) {
      console.error('Error unsubscribing:', err);
      setError(
        'Tapahtui virhe uutiskirjeen perumisessa. ' +
        'Linkki saattaa olla vanhentunut tai virheellinen.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Common styles for both success and main view
  const containerStyle = {
    maxWidth: 'sm',
    sx: { 
      px: isMobile ? 2 : 3,
      py: isMobile ? 2 : 3,
    }
  };

  const paperStyle = {
    sx: { 
      p: isMobile ? 3 : 4, 
      mt: isMobile ? 2 : 4,
      textAlign: 'center',
      borderRadius: isMobile ? 2 : 3,
      boxShadow: isMobile ? 1 : 3
    }
  };

  const logoBox = (
    <Box 
      sx={{ 
        mb: isMobile ? 2 : 3,
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <img 
        src={logo}
        alt="MyXline Logo" 
        style={{ 
          maxWidth: isMobile ? '160px' : '200px',
          width: '100%',
          height: 'auto',
          objectFit: 'contain'
        }}
      />
    </Box>
  );

  if (error) {
    return (
      <Container {...containerStyle}>
        <Box sx={{ mt: isMobile ? 2 : 4 }}>
          <Alert 
            severity="error"
            sx={{ 
              borderRadius: isMobile ? 1 : 2,
              '& .MuiAlert-message': {
                width: '100%'
              }
            }}
          >
            <AlertTitle>Virhe</AlertTitle>
            {error}
          </Alert>
        </Box>
      </Container>
    );
  }

  if (success) {
    return (
      <Container {...containerStyle}>
        <Paper {...paperStyle}>
          {logoBox}
          <Typography 
            variant={isMobile ? "h6" : "h5"} 
            gutterBottom
            sx={{ mb: 2 }}
          >
            Uutiskirje peruutettu
          </Typography>
          <Typography 
            color="text.secondary"
            sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
          >
            Olemme poistaneet sinut uutiskirjelistaltamme. 
            Et enää vastaanota meiltä markkinointiviestejä.
          </Typography>
          <Typography 
            sx={{ 
              mt: 2,
              fontSize: isMobile ? '0.9rem' : '1rem'
            }} 
            color="text.secondary"
          >
            Jos muutat mielesi, voit aina tilata uutiskirjeemme uudelleen verkkosivuiltamme.
          </Typography>
          <Box sx={{ mt: isMobile ? 2 : 3 }}>
            <Button 
              variant="contained" 
              color="primary" 
              href="/"
              fullWidth={isMobile}
              sx={{ 
                minWidth: isMobile ? '100%' : '200px',
                py: isMobile ? 1.5 : 1
              }}
            >
              Palaa etusivulle
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container {...containerStyle}>
      <Paper {...paperStyle}>
        {logoBox}
        <Typography 
          variant={isMobile ? "h6" : "h5"} 
          gutterBottom
          sx={{ mb: 2 }}
        >
          Peruuta uutiskirjeen tilaus
        </Typography>
        <Typography 
          color="text.secondary" 
          paragraph
          sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
        >
          Haluatko varmasti perua MyXline uutiskirjeen tilauksen?
        </Typography>
        <Box 
          sx={{ 
            mt: isMobile ? 2 : 3,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 2 : 1,
            justifyContent: 'center'
          }}
        >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUnsubscribe}
            disabled={loading}
            fullWidth={isMobile}
            sx={{ 
              minWidth: isMobile ? '100%' : '180px',
              py: isMobile ? 1.5 : 1
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Kyllä, peruuta tilaus'}
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            href="/"
            fullWidth={isMobile}
            sx={{ 
              minWidth: isMobile ? '100%' : '180px',
              py: isMobile ? 1.5 : 1
            }}
          >
            Ei, palaa etusivulle
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Newsletter;