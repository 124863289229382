// pages/DeliveryStatusPage.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { getUrl } from "../utils/urls";
import GoogleMap from "../components/GoogleMap";
import { Box, Typography, CircularProgress, Paper, Link } from "@mui/material";
import {
  Phone,
  AccessTime,
  Person,
  LocalShipping,
  CheckCircle,
  Pending,
} from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import recordPageView from "../utils/recordPageView"; // Import the utility
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DeliveryStatusPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize navigate

  const { id } = useParams();
  const [statusData, setStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    const deviceInfo = {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    };

    // Call the reusable recordPageView function
    recordPageView({
      deliveryId: id,
      userId: "anonymous",
      deviceInfo,
      source: "sms-click",
    });

    const fetchStatusData = async () => {
      try {
        const currentUrl = window.location.href;
        console.log('fetching...')
        const response = await axios.post(`${getUrl("tracking")}`, {
          id,
          origin: currentUrl,
        });
        setStatusData(response.data);
        
      } catch (err) {
        setError("Failed to fetch delivery status");
      } finally {
        setLoading(false);
      }
    };

    fetchStatusData();
  }, [id]);

  const statusConfig = {
    completed: { label: "Toimitettu", icon: <CheckCircle color="success" /> },
    active: { label: "Odottaa kuljettajaa", icon: <Pending color="action" /> },
    ongoing: {
      label: "Kuljettaja löytynyt",
      icon: <LocalShipping color="primary" />,
    },
    picked: { label: "Noudettu", icon: <LocalShipping color="secondary" /> },
    pending: { label: "Odottaa kuljettajaa", icon: <Pending color="action" /> },
  };

  const renderStatusMessage = (status) =>
    statusConfig[status] || { label: "Status not available", icon: null };

  // Rotating advertisement messages
  const ads = [
    t("marketingTextEuro"),
    t("marketingTextLanding1"),
    t("marketingTextLanding2"),
    t("marketingTextLanding3"),
    t("marketingTextLanding4"),
  ];

  // Cycle through advertisement messages every 5 seconds
  useEffect(() => {
    const adInterval = setInterval(() => {
      setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 10000); // 5 seconds interval
    return () => clearInterval(adInterval);
  }, [ads.length]);

  const { label, icon } = renderStatusMessage(statusData?.status);
  const handleAdClick = () => {
    navigate(`/eurolla/${id}`, { state: { source: "eurolla-banner" } }); // Pass the source parameter
  };

  return (
    <Box padding={2}>
      {/* Advertisement Section */}
      <Paper
        onClick={handleAdClick}
        elevation={4} // Slightly higher elevation to make it more prominent
        sx={{
          padding: 2,
          marginBottom: 2,
          backgroundColor: "#e0f7fa", // Light teal background for attention
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "#b2ebf2", // Darker teal on hover
          },
        }}
      >
        <Box sx={{ textAlign: "center", color: "#00796b" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {ads[currentAdIndex]}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.5,
              marginTop: 1,
              fontWeight: 500,
              color: "#004d40", // Darker shade for contrast
            }}
          >
            {t('clickHere')}
            <ArrowForwardIcon fontSize="small" />
          </Typography>
        </Box>
      </Paper>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      ) : (
        <>
          {/* Upper Paper with Basic Details and Courier Info */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t("deliveryStatus")}
            </Typography>

            <Box display="flex" alignItems="center" marginBottom={1}>
              {icon}
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                <strong>{t("status")}:</strong> {label}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginTop={1}>
              <Person sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                <strong>{t("courierName")}:</strong> {statusData.acceptedByName}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginTop={1}>
              <Phone sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                <strong>{t("courierPhone")}:</strong>
                <Link
                  href={`tel:${statusData.courierPhone}`}
                  underline="none"
                  sx={{ marginLeft: 1, color: "primary.main" }}
                >
                  {statusData.courierPhone}
                </Link>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" marginTop={1}>
              <AccessTime sx={{ marginRight: 1 }} />
              <Typography variant="body2">
                <strong>{t("lastUpdated")}</strong>{" "}
                {moment(statusData.updated).format("HH:mm")}
              </Typography>
            </Box>
          </Paper>

          {/* Lower Paper with Delivery Details if Available */}
          {(statusData.status === "ongoing" ||
            statusData.status === "picked") && (
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("deliveryDetails")}
              </Typography>
              <Typography variant="body2">
                <strong>{t("pickupPlaceholder")}</strong> {statusData.from}
              </Typography>
             {statusData.deliveryType !== 'new-multi' && <Typography variant="body2">
                <strong>{t("dropoffPlaceholder")}</strong> {statusData.to}
              </Typography>}
              {statusData.deliveryType !== 'new-multi' && <Typography variant="body2">
                <strong>{t("deliveredBy")}</strong>{" "}
                {moment(statusData.targetTime).format("HH:mm DD.MM")}
              </Typography>}
            </Paper>
          )}

          {/* Display Google Map if coordinates are available */}
          {statusData.coordinates && (
            <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("map")}
              </Typography>
              <Box sx={{ width: "100%", height: "300px", marginTop: 1 }}>
                <GoogleMap
                  coordinates={statusData.coordinates}
                  courierLocation={{
                    latitude: statusData.latitude,
                    longitude: statusData.longitude,
                  }}
                />
              </Box>
            </Paper>
          )}
        </>
      )}
    </Box>
  );
};

export default DeliveryStatusPage;
