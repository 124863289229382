import KEYS from '../keys/google.json';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../App';

export const getCoordinatesByPlaceId = async (placeId) => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${KEYS.GEOCODING}`;
  
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      if (data.status === 'OK' && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } else {
        console.error('Error fetching coordinates:', data.error_message || 'Unknown error');
        return null;
      }
    } catch (error) {
      console.error('Error in fetching coordinates:', error);
      return null;
    }
  };
  

  export const getDirectionsByPlaceIds = async (fromPlaceId, toPlaceId) => {
    try {
      const functions = getFunctions(app);
      const getDirections = httpsCallable(functions, "getDirectionsByPlaceIds"); // Reference the Firebase Function
      const result = await getDirections({ fromPlaceId, toPlaceId }); // Pass parameters
      return result.data; // Access the returned data
    } catch (error) {
      console.error("Error fetching directions from Cloud Function:", error);
      return null;
    }
  };