import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";

function GeneralTerms() {
  return (
    <div>
      <Container maxWidth="lg" sx={{ marginTop: 4 }}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" sx={{ marginBottom: 2 }}>
          <Link color="inherit" href="/tietosuoja">
            Käyttöehdot ja tietosuoja
          </Link>
          <Typography color="textPrimary">
            Palvelun yleiset ehdot
          </Typography>
        </Breadcrumbs>

        <Typography variant="h4" component="h1" gutterBottom>
          Palvelun yleiset ehdot
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
            Palvelun yleiset ehdot
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Käyttäjätili
            </Typography>
            <Typography variant="body1" paragraph>
              Luodessasi käyttäjätilin Palveluun sinun tulee antaa meille paikkansa pitävät,
              täydelliset ja ajantasaiset tiedot. Mikäli luovuttamasi tiedot eivät täytä näitä
              vaatimuksia, tämä katsotaan näiden Ehtojen rikkomiseksi, joka voi johtaa
              käyttäjätilisi sulkemiseen Palvelussa MyXline.
            </Typography>
            <Typography variant="body1" paragraph>
              Sinä olet yksin vastuussa käyttäjätilisi toimista Palvelussa sekä sen mahdollisesti
              aiheuttamista vahingoista. Sinä olet vastuussa laitteesi ja niiden tietojesi (kuten
              käyttäjätunnus ja salasana) suojaamisesta, joilla käytät Palvelua tai joilla
              kirjaudut Palveluun tai joita hyödyntämällä voit käyttää Palvelua tai tunnistautua
              Palvelussa tai kolmannen osapuolen tarjoamassa palvelussa. Sinulla ei ole oikeutta
              luoda useita käyttäjätilejä tai käyttäjätiliä muille kuin itsellesi.
            </Typography>
            <Typography variant="body1" paragraph>
              Sitoudut siihen, että et luovuta henkilökohtaista käyttäjätunnustasi, salasanaasi
              tai muita Palveluun kirjautumiseen tai tunnistautumiseen käytettäviä
              käyttäjätietojasi kenellekään kolmannelle osapuolelle. Sitoudut ilmoittamaan meille
              välittömästi, mikäli tietoosi tulee tietoturvarikkomus tai jos epäilet jonkun
              käyttävän käyttäjätiliäsi oikeudetta.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Palvelu
            </Typography>
            <Typography variant="body1" paragraph>
              Huomaathan, että Palvelun käyttöön liittyy tiedonsiirtokustannuksia kuten
              puhelinoperaattori- ja mobiiliverkkomaksuja.
            </Typography>
            <Typography variant="body1" paragraph>
              Meillä on milloin tahansa oikeus muuttaa, muokata ja päivittää Palvelua tai mitä
              tahansa osaa Palvelusta. Pyrimme kohtuullisin keinoin tiedottamaan sinulle etukäteen
              olennaisista muutoksista Palveluun, jotka voisivat vaikuttaa Palvelun käyttöä
              haittaavasti.
            </Typography>
            <Typography variant="body1" paragraph>
              Sinulla ei ole oikeutta tehdä Palvelun taustajärjestelmiin liittyviä automatisoituja
              kyselyitä.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Immateriaalioikeudet
            </Typography>
            <Typography variant="body1" paragraph>
              Sinulla ei ole oikeutta purkaa, murtaa tai muuten selvittää Palvelun suunnittelua,
              toimintalogiikkaa, rakennetta tai toiminnallisuuksia (mukaan lukien algoritmit ja
              lähdekoodi) tai jakaa Palvelua edelleen.
            </Typography>
            <Typography variant="body1" paragraph>
              Antamalla meille palautetta Palveluun mahdollisesti liittyvistä virheistä,
              ongelmista tai muista vastaavista tai Palvelun kehittämisideoista siirrät meille
              kaikki oikeudet kyseiseen palautteeseen kuten esimerkiksi oikeuden käyttää antamaasi
              palautetta meidän parhaaksi katsomalla tavalla.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Irtisanominen
            </Typography>
            <Typography variant="body1" paragraph>
              Meillä on oikeus ilman ennakkovaroitusta tai mitään vastuuta irtisanoa välittömin
              vaikutuksin nämä Ehdot ja käyttäjätilisi, estää pääsy Palveluun tai rajoittaa
              Palvelun käyttöä sinun osaltasi milloin tahansa esimerkiksi, jos rikot Ehtoja.
            </Typography>
            <Typography variant="body1" paragraph>
              Irtisanomisen yhteydessä sinun oikeutesi käyttää Palvelua päättyy välittömästi. Jos
              haluat poistaa käyttäjäprofiilisi ja lopettaa Palvelun käytön, voit tehdä sen
              pyytämällä käyttäjätilin poistoa MyXlinelta tai lopettamalla Palvelun käytön.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Vastuuvapaus
            </Typography>
            <Typography variant="body1" paragraph>
              Sinä käytät Palvelua täysin omalla vastuullasi. Palvelu on tarjottu sinulle
              sellaisena kuin se on. Palvelu tarjotaan sinulle ilman mitään takuita, kuten
              esimerkiksi takuuta Palvelun sopivuudesta tiettyyn käyttötarkoitukseen, oikeuksien
              loukkaamattomuudesta tai Palvelun toiminnallisuudesta.
            </Typography>
            <Typography variant="body1" paragraph>
              MyXline Oy ja sen lisenssinantajat eivät takaa tai vastaa siitä, että a) Palvelu
              toimisi katkotta, turvallisesti tai olisi saatavilla ajankohdasta ja sijainnista
              riippumatta; b) että Palvelun mahdolliset viat tai virheet korjattaisiin; c) että
              Palvelu on vapaa viruksista tai muista haittaohjelmista; tai d) että Palvelun käytön
              lopputulos vastaisi toiveitasi.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Sovellettava lainsäädäntö
            </Typography>
            <Typography variant="body1" paragraph>
              Näihin Ehtoihin sovelletaan Suomen lakia pois lukien sen lainvalintaa koskevat
              säännökset.
            </Typography>
            <Typography variant="body1" paragraph>
              Näihin Ehtoihin liittyvät erimielisyydet pyritään ensisijaisesti ratkaisemaan
              osapuolten välisin neuvotteluin. Mikäli erimielisyyttä ei kyetä ratkaisemaan
              osapuolten välisissä neuvotteluissa, erimielisyys ratkaistaan lopullisesti Helsingin
              käräjäoikeudessa.
            </Typography>
            <Typography variant="body1" paragraph>
              Kuluttaja voi saattaa näitä Ehtoja koskevan erimielisyyden kuluttajariitalautakunnan
              ratkaistavaksi (<Link href="http://www.kuluttajariita.fi" target="_blank" rel="noopener noreferrer">www.kuluttajariita.fi</Link>).
              Ennen asian viemistä kuluttajariitalautakunnan käsittelyyn kuluttajan tulee olla
              yhteydessä maistraattien kuluttajaneuvontaan (
              <Link href="http://www.kuluttajaneuvonta.fi" target="_blank" rel="noopener noreferrer">www.kuluttajaneuvonta.fi</Link>).
            </Typography>
            <Typography variant="body1" paragraph>
              Vaikka emme välittömästi ryhtyisi mihinkään toimiin Ehtojen rikkomisen johdosta,
              tämä ei tarkoita sitä, että luopuisimme mistään oikeuksistamme. Mikäli jotain
              Ehtojen määräystä ei pidettäisi pätevänä tai täytäntöönpanokelpoisena, tämä ei
              millään tavalla vaikuta muihin Ehtojen määräysten voimassaoloon. Nämä Ehdot ovat
              sitovia ja velvoittavia, ja täten säilyvät voimassa, vaikka jokin Ehtojen
              määräyksistä onkin lainvastainen tai täytäntöönpanokelpoinen.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              Muutokset
            </Typography>
            <Typography variant="body1" paragraph>
              Meillä on oikeus muuttaa näitä Ehtoja. Suosittelemme tarkistamaan säännöllisesti
              Ehtoja. Jos teemme olennaisia muutoksia Ehtoihin, ilmoitamme siitä etukäteen ja
              kerromme muutoksista Palvelussa tai sähköpostitse, ja nämä uudet Ehdot tulevat voimaan
              kun olet hyväksynyt ne. Jos et hyväksy muutettuja ehtoja, sinun tulee lopettaa
              Palvelun käyttö. Palvelun jatkuva käyttö muutosten jälkeen merkitsee, että hyväksyt
              uudet Ehdot.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default GeneralTerms;
