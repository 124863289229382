import { t } from 'i18next';

export const checkFields = (fields) => {
    const { senderName, senderPhone, receiverName, receiverPhone, senderEmail, from, to } = fields;
    let totalErrorText = '';

    // Check sender name
    if (!senderName || senderName.length < 4) {
        totalErrorText += t('errors.senderName');  // localized key for sender name error
    }

    // Check sender phone
    if (!senderPhone || senderPhone.length < 7) {
        totalErrorText += t('errors.senderPhone');  // localized key for sender phone error
    }

    // Check receiver name
    if (!receiverName || receiverName.length < 4) {
        totalErrorText += t('errors.receiverName');  // localized key for receiver name error
    }

    // Check receiver phone
    if (!receiverPhone || receiverPhone.length < 7) {
        totalErrorText += t('errors.receiverPhone');  // localized key for receiver phone error
    }

    // Check sender email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!senderEmail || !emailRegex.test(senderEmail)) {
        totalErrorText += t('errors.senderEmail');  // localized key for sender email error
    }

    // Check "from" address
    if (!from || from.length < 5) {
        totalErrorText += t('errors.fromAddress');  // localized key for "from" address error
    }

    // Check "to" address
    if (!to || to.length < 5) {
        totalErrorText += t('errors.toAddress');  // localized key for "to" address error
    }

    // Return the total error message if any, otherwise return null
    return totalErrorText ? totalErrorText.trim() : null;
};
