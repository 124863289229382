// BusPage.js
import React from "react";
import { Box, Typography, Paper, Container, Button } from "@mui/material";
import { LocalShipping, LocationCity, AttachMoney } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import busImage from "../assets/buss.png"; // Use your bus image path

const BusPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ my: 6 }}>
      {/* Hero Section */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <img 
          src={busImage} 
          alt="Bus Service" 
          style={{ width: "100%", height: "auto", maxWidth: "600px" }} // Limit max width
        />
        <Typography variant="h3" gutterBottom sx={{ mt: 2, fontWeight: "bold" }}>
          {t("busService.title", "MyXline City-to-City Deliveries")}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {t("busService.subtitle", "Affordable and Reliable Intercity Deliveries with MyXline")}
        </Typography>
      </Box>

      {/* Service Details Section */}
      <Paper elevation={3} sx={{ padding: 4, backgroundColor: "#f9f9f9", mb: 4 }}>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <LocalShipping sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.howItWorksTitle", "How It Works")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.howItWorksDescription",
            "With MyXline's intercity delivery service, you can send packages from one city to another efficiently and at a low cost. Our service allows you to drop off your package at a designated location in your city, and we handle the rest, delivering it to your recipient in the destination city."
          )}
        </Typography>

        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <LocationCity sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.cityToCityTitle", "City-to-City Coverage")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.cityToCityDescription",
            "We connect major cities across the country, allowing you to send packages to your destination quickly and reliably. Drop-offs and pickups are handled at central points for convenience."
          )}
        </Typography>

        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <AttachMoney sx={{ fontSize: 40, color: "#1976d2", mr: 2 }} />
          <Typography variant="h5" gutterBottom>
            {t("busService.affordableTitle", "Affordable Pricing")}
          </Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            "busService.affordableDescription",
            "Our city-to-city service is designed to be budget-friendly. Starting at just 1 € + VAT, you can enjoy a cost-effective way to send your packages and items across cities."
          )}
        </Typography>
      </Paper>

      {/* Call-to-Action Section */}
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          href="https://app.myxline.fi/asiakassivu"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textTransform: "none",
            padding: "12px 24px",
            fontSize: "16px",
          }}
        >
          {t("busService.sendNow", "Send a Package Now")}
        </Button>
      </Box>
    </Container>
  );
};

export default BusPage;
