import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translations
import en from "./locales/en/translation.json";
import fi from "./locales/fi/translation.json";
import sv from "./locales/sv/translation.json";
import se from "./locales/se/translation.json";
import ru from "./locales/ru/translation.json";
import et from "./locales/et/translation.json";
import ar from "./locales/ar/translation.json";
import fr from "./locales/fr/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fi: { translation: fi },
      en: { translation: en },
      sv: { translation: sv },
      se: { translation: se },
      ru: { translation: ru },
      et: { translation: et },
      ar: { translation: ar },
      fr: { translation: fr }
    },
    fallbackLng: "en", // Fallback to English if the language is not available
    detection: {
      order: ["localStorage", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
