// components/GoogleMap.js
import React from 'react';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import KEYS from '../keys/google.json';
import { Room, DirectionsRun, Place } from '@mui/icons-material';

function GoogleMap({ coordinates, courierLocation }) {
  const { from, to } = coordinates;
  
  // Check if coordinates for start, end, and courier are valid
  const isFromValid = from && from.latitude && from.longitude
  const isToValid = to && to.latitude !== null && to.longitude !== null;
  const isCourierValid = courierLocation && courierLocation.latitude !== null && courierLocation.longitude !== null;

  const suomi = { lat: 64.9631, lng: 25.004 };
  const positionStart = isFromValid ? { lat: from.latitude, lng: from.longitude } : suomi;
  const positionEnd = isToValid ? { lat: to.latitude, lng: to.longitude } : suomi;

  const courierPosition = isCourierValid ? { lat: courierLocation.latitude, lng: courierLocation.longitude } : null;
  const markerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
  };
  const defaultCenter = isCourierValid ? courierPosition : positionStart || suomi;
  const defaultZoom = (isFromValid || isToValid || isCourierValid) ? 10 : 5;
  // Check if there’s an error in the coordinates
  if (!isFromValid && !isToValid && !isCourierValid) {
    return <div></div>;
  }
  
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <APIProvider apiKey={KEYS.MAPS}>
        <Map defaultCenter={defaultCenter} defaultZoom={defaultZoom} mapId="DEMO_MAP_ID">
        {isFromValid && (
            <AdvancedMarker position={positionStart}>
              <div style={{ ...markerStyle, backgroundColor: '#e0f7e9' }}>
                <Room style={{ color: '#0f9d58', fontSize: '24px' }} />
              </div>
            </AdvancedMarker>
          )}

          {/* End Location Marker with Red Background */}
          {isToValid && (
            <AdvancedMarker position={positionEnd}>
              <div style={{ ...markerStyle, backgroundColor: '#fdecea' }}>
                <Place style={{ color: '#d32f2f', fontSize: '24px' }} />
              </div>
            </AdvancedMarker>
          )}

          {/* Courier Location Marker with Blue Background */}
          {isCourierValid && (
            <AdvancedMarker position={courierPosition}>
              <div style={{ ...markerStyle, backgroundColor: '#e3f2fd' }}>
                <DirectionsRun style={{ color: '#1976d2', fontSize: '24px' }} />
              </div>
            </AdvancedMarker>
          )}

        </Map>
      </APIProvider>
    </div>
  );
}

export default GoogleMap;
